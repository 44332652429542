import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import { TesimonialWapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, SpanStyled, TextStyled } from '../../../Styled/Elements'
import { TestimonailData, TextimonailImgData } from "../../sliderData/Data";
import { ArrowLeft, ArrowRight } from "../../../Styled/AllImages";
import { useTranslation } from "react-i18next";

const Testimonial = () => {
    const { t } = useTranslation()
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const slider1 = useRef(null);
    const slider2 = useRef(null);

    useEffect(() => {
        setNav1(slider1.current);
        setNav2(slider2.current);
    }, []);
    const Navsettings = {
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        focusOnSelect: true,
        pauseOnHover: false,
        autoplaySpeed: 5000,
        speed: 500,
        autoplay: true,
    }
    const settings = {
        slidesToShow: 5,
        centerMode: true,
        centerPadding: '0px',
        swipeToSlide: true,
        focusOnSelect: true,
        pauseOnHover: false,
        autoplaySpeed: 5000,
        speed: 500,
        autoplay: true,
        nextArrow: <ArrowRight />,
        prevArrow: <ArrowLeft />,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 3,

                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,

                }
            }
        ]
    }

    return (
        <TesimonialWapper id='testimonials'>
            <Container className='mt-5'>
                <Row>
                    <Box className='d-flex flex-column align-items-center justify-content-center text-center my-3 mt-3'>
                        <SpanStyled className='subHeading' color='var(--textGrey)' size='18px'>{t('Testimonials')}</SpanStyled>
                        <HeadingStyled lh='45px' className='themeTextGradient'>{t('Testimonials_heading')}</HeadingStyled>
                        <TextStyled className='my-3'>{t('Testimonials_heading_content')}</TextStyled>
                    </Box>
                </Row>
            </Container>
            <Container>
                <Row className="row-slider">
                    <Col>
                        <Box className="testi-nav">
                            <Slider asNavFor={nav2} ref={slider1} {...Navsettings}>
                                {
                                    TestimonailData.map((list, key) => (
                                        <Box key={key} className="tesSlideContent">
                                            <SpanStyled className="vector"></SpanStyled>
                                            <Box className="tesSlideContentCol">
                                                <TextStyled>{t(list.text)}</TextStyled>
                                                <HeadingStyled size='16px' color='var(--themeColor)'>{t(list.subHeading)}</HeadingStyled>
                                                <HeadingStyled size='16px' color='var(--themeColor)'>{t(list.heading)}</HeadingStyled>
                                            </Box>
                                            <SpanStyled className="vector2"></SpanStyled>
                                            <SpanStyled className="triangle-3"></SpanStyled>
                                        </Box>
                                    ))
                                }
                            </Slider>
                        </Box>
                        <Box className="slider-nav-wrapper">
                            <Box className="slider-nav-container">
                                <Slider asNavFor={nav1} ref={slider2} {...settings}>
                                    {
                                        TextimonailImgData.map((list, key) => (
                                            <Box>
                                                <Box className="client-img">
                                                    {list.img}
                                                </Box>
                                            </Box>
                                        ))
                                    }
                                </Slider>
                            </Box>
                        </Box>
                    </Col>
                </Row>
            </Container>
        </TesimonialWapper>
    )
}

export default Testimonial
