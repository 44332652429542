import React from 'react'
import { ProjectWapper } from './Styled'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { ProjectBg } from '../../../Styled/AllImages'
import { ProjectDigitalData, ProjectOtherData } from '../../sliderData/Data'
import { Box, HeadingStyled, SpanStyled, TextStyled } from '../../../Styled/Elements'

const Projects = () => {
    const { t } = useTranslation();
    return (
        <ProjectWapper>
            <Container>
                <Row>
                    <Box className='d-flex flex-column align-items-center justify-content-center text-center my-3'>
                        <SpanStyled className='subHeading' color='var(--textGrey)' size='18px'>{t('Why_Choose_Us')}</SpanStyled>
                        <HeadingStyled lh='45px' className='themeTextGradient'>{t('project_heading')}</HeadingStyled>
                        <TextStyled className='my-3'>{t('project_content')}</TextStyled>
                    </Box>
                </Row>
            </Container>
            <Container className='comparing my-4'>
                <Row>
                    <Col md='12' lg='6' className='project-digital'>
                        <img className='project-bg' src={ProjectBg} alt='img'/>
                        <HeadingStyled size='28px' color='var(--themeColor)' weight='normal'>{t('project_detail_heading')}</HeadingStyled>
                        {
                            ProjectDigitalData.map((list, key) => (
                                <Box key={key} className='project-wapper'>
                                    <Box className='project-content'>
                                        {list.img}
                                        <Box>
                                            <HeadingStyled color='var(--themeColor)' size='18px' weight='normal'>{t(list.heading)}</HeadingStyled>
                                            <TextStyled size="14px">{t(list.text)}</TextStyled>
                                        </Box>
                                    </Box>
                                </Box>
                            ))
                        }
                    </Col>
                    <Col md='12' lg='6' className='project-other'>
                        <HeadingStyled size='28px' color='var(--themeColor)' weight='normal'>{t('project_other_heading')}</HeadingStyled>
                        {
                            ProjectOtherData.map((list, key) => (
                                <Box key={key} className='project-wapper'>
                                    <Box className='project-content compaire'>
                                        {list.img}
                                        <Box>
                                            <HeadingStyled color='var(--themeColor)' size='18px' weight='normal'>{t(list.heading)}</HeadingStyled>
                                            <TextStyled size="14px">{t(list.text)}</TextStyled>
                                        </Box>
                                    </Box>
                                </Box>
                            ))
                        }
                    </Col>
                </Row>
            </Container>
        </ProjectWapper >
    )
}

export default Projects
