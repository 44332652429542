import { Banner1, Brand, Client, Design, Digital, DigitalService, Graphic, Mobile, OtherClient, OtherService, OtherSolution, OtherTimer, Portfolio1, Portfolio2, Portfolio3, Portfolio4, Portfolio5, Portfolio6, Portfolio7, Portfolio8, Portfolio9, Portfolio10, Portfolio11, Portfolio12, Portfolio13, Solution, Testimonail1, Testimonail2, Timer, Website, } from "../../Styled/AllImages";
import { LazyImage } from "../../Styled/Elements";


export const NavDataConfig = [
    {
        to: 'home',
        label: 'Home',
    },
    {
        to: 'about',
        label: 'About',
    },
    {
        to: 'services',
        label: 'Services',
    },
    {
        to: 'portfolio',
        label: 'Portfolio',
    },
    {
        to: 'testimonials',
        label: 'Testimonials',
    },
    {
        to: 'contact',
        label: 'contactUs',
    },
]
export const bannerDataConfig = [
    {
        subHeading: 'Innovation_Meets',
        heading: 'Software',
        heading2: 'Brilliance',
        des: 'Pioneering_excellence',
        label: 'Whatsapp',
        vedio: 'Watch_Vedio',
        img: <LazyImage src={Banner1} />
    },
    // {
    //     subHeading: 'Innovation_Meets',
    //     heading: 'Software',
    //     heading2: 'Brilliance',
    //     des: 'Pioneering_excellence',
    //     label: 'Whatsapp',
    //     vedio: 'Watch_Vedio',
    //     img: <LazyImage src={Banner1} />
    // },
]
export const serviceCardData = [
    {
        img: <LazyImage src={Brand} />,
        heading: "Brand_Development",
        text: "Brand_Development_content",
    },
    {
        img: <LazyImage src={Digital} />,
        heading: "Digital_Marketing",
        text: "Digital_Marketing_content",
    },
    {
        img: <LazyImage src={Website} />,
        heading: "Website_Development",
        text: "Website_Development_content",
    },
    {
        img: <LazyImage src={Mobile} />,
        heading: "Mobile_App_Development",
        text: "Mobile_App_Development_content",
    },
    {
        img: <LazyImage src={Design} />,
        heading: "UX_UI_Design",
        text: "UX_UI_Design_content",
    },
    {
        img: <LazyImage src={Graphic} />,
        heading: "Graphic_Design",
        text: "Graphic_Design_content",
    },


]
export const ProjectDigitalData = [
    {
        img: <LazyImage src={DigitalService} />,
        heading: "Expertise_Digital_Services",
        text: "Expertise_Digital_Services_content",
    },
    {
        img: <LazyImage src={Solution} />,
        heading: "Customized_Solutions",
        text: "Customized_Solutions_content",
    },
    {
        img: <LazyImage src={Timer} />,
        heading: "Timely_Delivery",
        text: "Timely_Delivery_content",
    },
    {
        img: <LazyImage src={Client} />,
        heading: "Client_Centric",
        text: "Client_Centric_content",
    },
]
export const ProjectOtherData = [
    {
        img: <LazyImage src={OtherService} />,
        heading: "Incompetence_Digital_Services",
        text: "Incompetence_Digital_Services_content",
    },
    {
        img: <LazyImage src={OtherSolution} />,
        heading: "Non_Customized_Solutions",
        text: "Non_Customized_Solutions_content",
    },
    {
        img: <LazyImage src={OtherTimer} />,
        heading: "Delayed_Delivery",
        text: "Delayed_Delivery_content",
    },
    {
        img: <LazyImage src={OtherClient} />,
        heading: "Self_Centric",
        text: "Self_Centric_content",
    },
]
export const PortfolioDataConfig = [
    {
        img: <LazyImage src={Portfolio1} />
    },
    {
        img: <LazyImage src={Portfolio2} />
    },
    {
        img: <LazyImage src={Portfolio3} />
    },
    // {
    //     img: <LazyImage src={Portfolio4} />
    // },
    {
        img: <LazyImage src={Portfolio5} />
    },
    {
        img: <LazyImage src={Portfolio6} />
    },
    {
        img: <LazyImage src={Portfolio3} />
    },
    // {
    //     img: <LazyImage src={Portfolio4} />
    // },
    {
        img: <LazyImage src={Portfolio5} />
    },
    {
        img: <LazyImage src={Portfolio6} />
    },
    // {
    //     img: <LazyImage src={Portfolio7} />
    // },
    {
        img: <LazyImage src={Portfolio8} />
    },
    {
        img: <LazyImage src={Portfolio9} />
    },
    {
        img: <LazyImage src={Portfolio10} />
    },
    {
        img: <LazyImage src={Portfolio11} />
    },
    {
        img: <LazyImage src={Portfolio12} />
    },
]
export const PortfolioMobileAppsDataConfig = [
    {
        img: <LazyImage src={Portfolio3} />
    },
    {
        img: <LazyImage src={Portfolio13} />
    },
    
]
export const PortfolioGrafixDataConfig = [
    {
        img: <LazyImage src={Portfolio3} />
    },
    {
        img: <LazyImage src={Portfolio6} />
    },
    {
        img: <LazyImage src={Portfolio9} />
    },
    
]
export const PortfolioWebsiteDataConfig = [
    {
        img: <LazyImage src={Portfolio1} />
    },
    {
        img: <LazyImage src={Portfolio2} />
    },
    {
        img: <LazyImage src={Portfolio3} />
    },
    // {
    //     img: <LazyImage src={Portfolio4} />
    // },
    {
        img: <LazyImage src={Portfolio5} />
    },
    {
        img: <LazyImage src={Portfolio6} />
    },
    {
        img: <LazyImage src={Portfolio3} />
    },
    // {
    //     img: <LazyImage src={Portfolio4} />
    // },
    {
        img: <LazyImage src={Portfolio5} />
    },
    {
        img: <LazyImage src={Portfolio6} />
    },
    // {
    //     img: <LazyImage src={Portfolio7} />
    // },
    {
        img: <LazyImage src={Portfolio8} />
    },
    {
        img: <LazyImage src={Portfolio9} />
    },
    {
        img: <LazyImage src={Portfolio10} />
    },
    {
        img: <LazyImage src={Portfolio11} />
    },
    {
        img: <LazyImage src={Portfolio12} />
    },
]
export const PortfolioUIUXDataConfig = [
    {
        img: <LazyImage src={Portfolio1} />
    },
    {
        img: <LazyImage src={Portfolio2} />
    },
    {
        img: <LazyImage src={Portfolio3} />
    },
    // {
    //     img: <LazyImage src={Portfolio4} />
    // },
    {
        img: <LazyImage src={Portfolio5} />
    },
    {
        img: <LazyImage src={Portfolio6} />
    },
    {
        img: <LazyImage src={Portfolio3} />
    },
    // {
    //     img: <LazyImage src={Portfolio4} />
    // },
    {
        img: <LazyImage src={Portfolio5} />
    },
    {
        img: <LazyImage src={Portfolio6} />
    },
    // {
    //     img: <LazyImage src={Portfolio7} />
    // },
    {
        img: <LazyImage src={Portfolio8} />
    },
    {
        img: <LazyImage src={Portfolio9} />
    },
    {
        img: <LazyImage src={Portfolio10} />
    },
    {
        img: <LazyImage src={Portfolio11} />
    },
    {
        img: <LazyImage src={Portfolio12} />
    },
]
export const TestimonailData = [
    {
        subHeading: "TesName1",
        heading: "ProjectTitle1",
        text: "ProjectContent1",
    },
    {
        subHeading: "TesName2",
        heading: "ProjectTitle2",
        text: "ProjectContent2",
    },
    {
        subHeading: "TesName1",
        heading: "ProjectTitle1",
        text: "ProjectContent1",
    },
    {
        subHeading: "TesName2",
        heading: "ProjectTitle2",
        text: "ProjectContent2",
    },
    {
        subHeading: "TesName1",
        heading: "ProjectTitle1",
        text: "ProjectContent1",
    },
    {
        subHeading: "TesName2",
        heading: "ProjectTitle2",
        text: "ProjectContent2",
    },

]
export const TextimonailImgData = [
    {
        img: <LazyImage src={Testimonail1} />,
    },
    {
        img: <LazyImage src={Testimonail2} />,
    },
    {
        img: <LazyImage src={Testimonail1} />,
    },
    {
        img: <LazyImage src={Testimonail2} />,
    },
    {
        img: <LazyImage src={Testimonail1} />,
    },
    {
        img: <LazyImage src={Testimonail2} />,
    },

]