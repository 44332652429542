import React, { useState } from 'react'
import Slider from 'react-slick'
import { PortfolioWapper } from './Styled'
import { useTranslation } from 'react-i18next'
import { Container, Row } from 'react-bootstrap'
import { PortfolioDataConfig, PortfolioGrafixDataConfig, PortfolioMobileAppsDataConfig, PortfolioUIUXDataConfig, PortfolioWebsiteDataConfig } from '../../sliderData/Data'
import { ArrowLeft, ArrowRight } from '../../../Styled/AllImages'
import { Box, TextStyled, SpanStyled, HeadingStyled } from '../../../Styled/Elements'
import { TabButton } from '../../../Styled/CustomElements'

const Portfolio = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tabNumber) => {
      setActiveTab(tabNumber);
    };
    const settings = {
        arrows: true,
        autoplay: true,
        infinite: false,
        centerPadding: "0px",
        speed: 500,
        slidesToShow: 3,
        slidesPerRow: 1,
        rows: 1,
        nextArrow: <ArrowRight />,
        prevArrow: <ArrowLeft />,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 2,
                    slidesPerRow: 1,
                    rows: 1,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesPerRow: 1,
                    rows: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesPerRow: 1,
                    rows: 1,
                }
            }
        ]
    }
    return (
        <PortfolioWapper id='portfolio'>
            <Container>
                <Row>
                    <Box className='d-flex flex-column align-items-center justify-content-center text-center my-5'>
                        <SpanStyled className='subHeading' color='var(--textGrey)' size='18px'>{t('Discover')}</SpanStyled>
                        <HeadingStyled lh='45px' className='themeTextGradient'>{t('Portfolio_heading')}</HeadingStyled>
                        <TextStyled className='my-3'>{t('Portfolio_content')}</TextStyled>
                    </Box>
                </Row>
                <Box className="tabWrapperContainer">
                    <Box className="tabWrapper">
                        <TabButton label='All' className={activeTab === 1 ? 'active' : ''} onClick={() => handleTabClick(1)} />
                        <TabButton label='Mobile Apps' className={activeTab === 2 ? 'active' : ''} onClick={() => handleTabClick(2)} />
                        <TabButton label='Website' className={activeTab === 3 ? 'active' : ''} onClick={() => handleTabClick(3)} />
                        <TabButton label='UI/UX Design' className={activeTab === 4 ? 'active' : ''} onClick={() => handleTabClick(4)} />
                        <TabButton label='SEO' className={activeTab === 5 ? 'active' : ''} onClick={() => handleTabClick(5)} />
                        <TabButton label='Digital Marketing' className={activeTab === 6 ? 'active' : ''} onClick={() => handleTabClick(6)} />
                        <TabButton label='Graphics Design' className={activeTab === 7 ? 'active' : ''} onClick={() => handleTabClick(7)} />
                    </Box>
                </Box>
                {activeTab === 1 && 
                    <Box className=''>
                        <Slider {...settings}>
                            {
                                PortfolioDataConfig.map((list, key) => (
                                    <Box key={key}>
                                        {list.img}
                                    </Box>
                                ))
                            }
                        </Slider>
                    </Box>
                }
                {activeTab === 2 && 
                    <Box className=''>
                        <Slider {...settings}>
                            {
                                PortfolioMobileAppsDataConfig.map((list, key) => (
                                    <Box key={key}>
                                        {list.img}
                                    </Box>
                                ))
                            }
                        </Slider>
                    </Box>
                }
                {activeTab === 3 && 
                    <Box className=''>
                        <Slider {...settings}>
                            {
                                PortfolioWebsiteDataConfig.map((list, key) => (
                                    <Box key={key}>
                                        {list.img}
                                    </Box>
                                ))
                            }
                        </Slider>
                    </Box>
                }
                {activeTab === 4 && 
                    <Box className=''>
                        <Slider {...settings}>
                            {
                                PortfolioUIUXDataConfig.map((list, key) => (
                                    <Box key={key}>
                                        {list.img}
                                    </Box>
                                ))
                            }
                        </Slider>
                    </Box>
                }
                {activeTab === 5 && 
                    <Box className='d-flex w-100 text-center justify-content-center'>
                        No Data Found
                    </Box>
                }
                {activeTab === 6 && 
                    <Box className='d-flex w-100 text-center justify-content-center'>
                        No Data Found
                    </Box>
                }
                {activeTab === 7 && 
                    <Box className=''>
                        <Slider {...settings}>
                            {
                                PortfolioGrafixDataConfig.map((list, key) => (
                                    <Box key={key}>
                                        {list.img}
                                    </Box>
                                ))
                            }
                        </Slider>
                    </Box>
                }
            </Container>
        </PortfolioWapper>
    )
}


export default Portfolio
