import React from 'react'
import ContactForm from './ContactForm'
import { ContactWapper } from './Styled'
import { useTranslation } from 'react-i18next'
import { Container, Row } from 'react-bootstrap'
import { TextWithIcon } from '../../../Styled/CustomElements'
import { Email, Location, Phone } from '../../../Styled/AllImages'
import { Box, HeadingStyled, SpanStyled, TextStyled } from '../../../Styled/Elements'


const Contact = () => {
    const { t } = useTranslation();
    
    return (
        <ContactWapper id='contact'>
            <Container>
                <Row>
                    <Box className='d-flex flex-column align-items-center justify-content-center text-center mt-5 mb-3'>
                        <SpanStyled className='subHeading' color='var(--textGrey)' size='18px'>{t('Contact_Us')}</SpanStyled>
                        <HeadingStyled lh='45px' className='themeTextGradient'>{t('Get_in_Touch')}</HeadingStyled>
                        <TextStyled className='my-3'>{t('eady_to_embark')}</TextStyled>
                    </Box>
                </Row>
                <Row>
                    <Box className='location-card'>
                        {/* <TextWithIcon color='var(--themeColor)' icon={Phone} title='+971 55 792 4192' /> */}
                        <TextWithIcon color='var(--themeColor)' icon={Email} title='info@app4orce.com' />
                        <TextWithIcon color='var(--themeColor)' icon={Location} title={t('map')} />
                    </Box>
                </Row>
            </Container>
            <ContactForm />
        </ContactWapper>
    )
}


export default Contact
